@import '@launchpad-ui/tokens/dist/media-queries.css';

@keyframes floatingToggle {
  0% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes jetpackSmallScreen {
  0% {
    opacity: 1;
    height: 84px;
    transform: translateX(0);
  }
  50% {
    opacity: 0.9;
    height: 60px;
    transform: translateX(-3px);
  }
  100% {
    opacity: 1;
    height: 84px;
    transform: translateX(0);
  }
}

@keyframes jetpack {
  0% {
    opacity: 1;
    height: 126.5px;
    transform: translateX(0);
  }
  50% {
    opacity: 0.9;
    height: 100px;
    transform: translateX(-3px);
  }
  100% {
    opacity: 1;
    height: 126.5px;
    transform: translateX(0);
  }
}

.error-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.125rem;
  line-height: 1.5;
  color: var(--lp-color-white-950);
  background-color: rgba(33, 33, 33, 0.97);
  background-image: linear-gradient(
      180deg,
      rgba(33, 33, 33, 0.97) 15.47%,
      rgba(40, 40, 40, 0.45) 52.79%,
      rgba(33, 33, 33, 0.97) 86.92%
    ),
    url('https://static.launchdarkly.com/app/s/img/error_404_page.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

[data-theme='legacy'] .error-page {
  background: none;
  background-color: hsl(221.7, 56.3%, 12.5%);
}

.error-page header,
.error-page main {
  width: 40em;
}

.error-page header {
  padding: 3.75rem;
}

.error-page header a img {
  width: 12.5rem;
}

.error-page main {
  padding: 1.25rem;
  position: absolute;
  left: 20.625rem;
  top: 10rem;
}

.error-page main h1 {
  font-size: var(--lp-font-size-500);
  color: var(--lp-color-white-950);
  margin: 3.125rem 0 1.875rem;
}

.error-page a,
.error-page a:link {
  color: var(--lp-color-blue-400);
  text-decoration: underline;
}

[data-theme='legacy'] .error-page a,
[data-theme='legacy'] .error-page a:link {
  color: var(--lp-color-text-feedback-info);
}

.error-page a:hover,
.error-page a:active {
  text-decoration: none;
  color: var(--lp-color-white-950);
}

.error-page main footer {
  font-size: 15px;
  padding-top: 20px;
}

.error-page code {
  color: rgba(234, 243, 251, 0.6);
  background-color: transparent;
}

h5.copy {
  margin-top: 20px;
}

img.error-page-logo {
  filter: invert(100%);
}

[data-theme='legacy'] .error-page-logo {
  filter: none;
}

.no-access {
  background-color: rgba(33, 33, 33, 0.97);
  background-image: url('https://static.launchdarkly.com/app/s/img/no-access-grid.png');
  background-position: 50% calc(50% + 47px);
  background-repeat: no-repeat;
  background-size: cover;
  color: var(--lp-color-white-950);
  font-size: 1.125rem;
  line-height: 1.5;
  min-height: 100vh;
}

.no-access-header {
  padding: 3.75rem 3.75rem 3.4375rem;
}

.no-access-logo img {
  filter: invert(100%);
  width: 12.5rem;
}

.no-access-starburst {
  background-image: url('https://static.launchdarkly.com/app/s/img/no-access-starburst.png');
  background-repeat: no-repeat;
  height: 539px;
  position: absolute;
  right: 0;
  top: 10%;
  width: 302px;
  z-index: var(--stacking-above-new-context);
}

.no-access-main {
  background: rgba(88, 89, 91, 0.1);
  backdrop-filter: blur(28px);
  position: relative;
  z-index: calc(var(--stacking-above-new-context) + 1);
}

.no-access-main h1 {
  font-size: var(--lp-font-size-500);
  line-height: 1.133;
  margin: 3.125rem 0 1.25rem;
}

.no-access-content {
  margin: auto;
  max-width: 37.5rem;
  padding: 2.5rem 1.25rem 6.25rem;
  @media (--desktop) {
    margin-left: 20.625rem;
  }
}

.no-access a,
.no-access a:link {
  color: var(--lp-color-blue-400);
}

.no-access a:hover,
.no-access a:active {
  color: var(--lp-color-white-950);
}

.error-404-page {
  background-color: rgba(33, 33, 33, 0.97);
  background-image: url('https://static.launchdarkly.com/app/s/img/not-found/noise.png');
  background-repeat: repeat;
  min-height: 100vh;
  overflow: hidden;

  header {
    max-width: 71.25rem;
    width: 100%;
    margin: 0 auto;
    padding: 3.125rem 1.25rem;
  }

  header a img {
    width: 100%;
    max-width: 12.5rem;
  }

  a.ld-error-logo {
    div {
      max-width: 12.5rem;
      width: 100%;
      border-radius: 2px;
    }
    &:focus {
      outline: none;
      box-shadow: none;
      div {
        outline: none;
        box-shadow: var(--link-box-shadow-focus);
      }
    }
    &:focus:not(:focus-visible) {
      outline: none;
      div {
        box-shadow: none;
      }
    }
    &:focus-visible {
      div {
        box-shadow: var(--link-box-shadow-focus);
      }
    }
  }

  a {
    color: var(--lp-color-gray-300);
  }

  a[class*='_Button'] {
    background-color: var(--lp-color-bg-interactive-primary-base);
    border: none;
    border-radius: 0;
    color: var(--lp-color-white-950);
    cursor: pointer;
    display: inline-flex;
    font-family: var(--lp-font-family-base);
    font-size: var(--lp-font-size-300);
    line-height: 1.5em;
    padding: 0.6875rem 1.375rem;
    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
    white-space: nowrap;
    &:hover {
      background-color: var(--lp-color-blue-600);
    }
    &:focus {
      box-shadow:
        0 0 0 2px var(--lp-color-gray-900),
        0 0 0 4px var(--lp-color-blue-500);
    }
    &:focus:not(:focus-visible) {
      box-shadow: unset;
    }
    &:focus-visible {
      box-shadow:
        0 0 0 2px var(--lp-color-gray-900),
        0 0 0 4px var(--lp-color-blue-500);
    }
  }

  .container {
    width: 100%;
    max-width: 71.25rem;
    margin: 0 auto;
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-columns: 1fr;
    padding: 0 0.78125rem;
    gap: 0;
    @media (--desktop) {
      grid-template-columns: 32.5rem 1fr;
    }
  }

  .content {
    --text-gradient-a: #edf4c9;
    --text-gradient-b: #9eadf1;

    align-self: center;
    text-align: center;
    padding: 2.5rem 0 2.5rem;
    position: relative;
    z-index: var(--stacking-z-index-10);
    @media (--desktop) {
      padding: 0;
      text-align: left;
    }

    h1.gradientText {
      font-family: 'Audimat 3000', var(--lp-font-family-base);
      font-size: var(--lp-font-size-800);
      display: inline-block;
      background-color: var(--text-gradient-b);
      background-image: linear-gradient(90deg, var(--text-gradient-a) 0%, var(--text-gradient-b) 100%);
      background-size: 100%;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent;
      -moz-text-fill-color: transparent;
      margin-bottom: 0.25rem;
    }
    p {
      color: var(--lp-color-gray-300);
      font-weight: 400;
      font-size: var(--lp-font-size-500);
      margin-bottom: 1.875rem;
      line-height: 1.95rem;
    }
  }

  .images {
    position: relative;
    right: 0;

    @media (--desktop) {
      display: block;
      min-height: 40rem;
      right: -0.78125rem;
    }

    .toggle {
      width: 100%;
      max-width: 18.75rem;
      display: grid;
      margin: auto;
      position: relative;
      @media (--desktop) {
        position: absolute;
        z-index: var(--stacking-above-new-context);
        display: block;
        bottom: 10.3125rem;
        right: 4.6875rem;
        max-width: 25rem;
      }

      .toggleBody {
        vertical-align: top;
        position: relative;
        z-index: calc(var(--stacking-above-new-context) + 1);
      }
      .jet {
        object-fit: cover;
        position: absolute;
        height: auto;
        width: 40px;
        @media (--desktop) {
          width: 60px;
        }
      }
      .jetOne {
        z-index: var(--stacking-above-new-context);
        top: 238px;
        left: 96px;
        @media (--desktop) {
          top: 318px;
          left: 126px;
        }
      }
      .jetTwo {
        z-index: var(--stacking-above-new-context);
        animation-delay: 0.2s;
        top: 233px;
        left: 123px;
        @media (--desktop) {
          top: 309px;
          left: 160px;
        }
      }
    }
  }

  .floating-toggle {
    animation-name: floatingToggle;
    animation-iteration-count: infinite;
    animation-duration: 5s;
    animation-timing-function: cubic-bezier(0.4, 0.01, 0.6, 1);
  }
  .jet-flame-animation {
    animation-name: jetpackSmallScreen;
    animation-iteration-count: infinite;
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0, 0.3, 1, 0.7);
    @media (--desktop) {
      animation-name: jetpack;
    }
  }

  .darkStar {
    display: none;
    @media (--desktop) {
      position: absolute;
      z-index: var(--stacking-above-new-context);
      display: block;
      width: 5.3125rem;
      bottom: 6.25rem;
      left: 40%;
    }
  }
  .darkStarSmall {
    display: none;
    @media (--desktop) {
      position: absolute;
      z-index: var(--stacking-above-new-context);
      display: block;
      width: 4.25rem;
      opacity: 0.7;
      top: -2.5rem;
      left: 50%;
    }
  }
  .darkStarLong {
    display: none;
    @media (--desktop) {
      position: absolute;
      z-index: var(--stacking-above-new-context);
      display: block;
      width: 8.125rem;
      top: 8.125rem;
      left: -1.25rem;
    }
  }
  .planet {
    display: block;
    position: absolute;
    top: -5.4375rem;
    right: 0;
    height: auto;
    width: auto;
    max-width: 9.375rem;
    transform: rotate(60deg);
    @media (--desktop) {
      max-width: 18.90625rem;
    }
  }

  .planetSmall {
    display: none;
    max-width: 9.375rem;
    width: 100%;
    height: auto;
    @media (--desktop) {
      position: absolute;
      display: inline-block;
      bottom: -3.125rem;
      left: 1rem;
      transform: rotate(45deg);
    }
  }
  .stars {
    position: absolute;
    display: block;
    width: 100%;
    max-width: 90rem;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

:root[data-type-refresh] {
  .error-page main h1 {
    font: var(--lp-text-heading-1-semibold);
  }

  .no-access-main h1 {
    font: var(--lp-text-heading-1-semibold);
  }

  .error-404-page {
    a[class*='_Button'] {
      font: var(--lp-text-body-1-regular);
    }

    .content {
      h1.gradientText {
        font: var(--lp-text-display-1);
      }
    }
  }
}
